/** @jsxImportSource @emotion/react */
import { DOWNLOAD_REPORT_SELECTION } from "@constants/horizon";
import { HorizonContext } from "@context";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useSectors } from "@services/api/horizon";
import { useCountries } from "@services/api/horizon/Countries";
import { useFunnelStateMetricsExcel } from "@services/api/horizon/FunnelStateMetrics";
import { getExcelDateFmt } from "@utils/ExcelUtils";
import BrandSelector from "@views/ViewHorizon/components/Selectors/BrandSelector";
import CountrySelector from "@views/ViewHorizon/components/Selectors/CountrySelector";
import IndustrySelector from "@views/ViewHorizon/components/Selectors/IndustrySelector";
import saveAs from "file-saver";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const Selectors = () => {
  const { t } = useTranslation();
  const { currentCtx } = useContext(HorizonContext);

  const [clicked, setClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [excelOption, setExcelOption] = useState<null | boolean>(null);
  const openDownloadReportMenu = Boolean(anchorEl);

  const { data: sectors } = useSectors({ countryId: currentCtx.countryId });
  const { data: countries } = useCountries();

  const countryCodeById = useMemo(() => {
    const countryCodeById: { [key: string]: string } = {};
    (countries ?? []).forEach((country) => {
      countryCodeById[country.id.toString()] = country.code;
    });
    return countryCodeById;
  }, [countries]);

  const sectorsById = useMemo(() => {
    const sectorsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sectorsById[sector.id.toString()] = sector.label;
    });
    return sectorsById;
  }, [sectors]);

  const brandsById = useMemo(() => {
    const brandsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sector.brands.forEach((brand) => {
        brandsById[brand.id.toString()] = brand.label;
      });
    });
    return brandsById;
  }, [sectors]);

  const excelFileNameBySelection = useMemo(() => {
    const countryCode =
      countryCodeById[currentCtx.countryId] ?? currentCtx.countryId;
    const sectorName = sectorsById[currentCtx.sectorId] ?? currentCtx.sectorId;
    const brandName = brandsById[currentCtx.brandId] ?? currentCtx.brandId;
    return `Report_Funnel_State_${countryCode}_${sectorName}_${brandName}_${getExcelDateFmt()}.xlsx`;
  }, [
    sectorsById,
    brandsById,
    countryCodeById,
    currentCtx.sectorId,
    currentCtx.brandId,
    currentCtx.countryId
  ]);

  const handleDownloadReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isFetchingExcel) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseDownloadReportButton = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: DOWNLOAD_REPORT_SELECTION) => {
    const newOption = option === DOWNLOAD_REPORT_SELECTION.ALL;
    setExcelOption(newOption);
    setAnchorEl(null);
  };

  const {
    data: excelBlob,
    refetch: refetchExcel,
    isFetching: isFetchingExcel
  } = useFunnelStateMetricsExcel({
    sectorId: currentCtx.sectorId,
    allSectors: excelOption ?? false
  });

  const isEnabledDownloadSectorOption: boolean = useMemo(() => {
    return currentCtx.sectorId.length > 0;
  }, [currentCtx]);

  useEffect(() => {
    if (excelBlob && clicked && !isFetchingExcel) {
      saveAs(excelBlob, excelFileNameBySelection);
      setClicked(false);
      setExcelOption(null);
    }
  }, [excelFileNameBySelection, excelBlob, clicked, isFetchingExcel]);

  useEffect(() => {
    if (excelOption !== null) {
      setClicked(true);
      refetchExcel();
    }
  }, [excelOption, refetchExcel]);

  return (
    <Box sx={{ height: "100%" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2
        }}
        elevation={0}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Horizon
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <CountrySelector />
            <IndustrySelector />
            <BrandSelector customOpts={[]} />
          </Box>
        </Box>
        <Button
          variant="contained"
          disabled={!isEnabledDownloadSectorOption}
          onClick={handleDownloadReport}
          sx={{
            textTransform: "none"
          }}
        >
          {isFetchingExcel && (
            <CircularProgress size="1.5rem" sx={{ color: "white" }} />
          )}
          {!isFetchingExcel && t("horizon.brand_equity.download_forecast")}
        </Button>
        <Menu
          id="brand-equity-download-report-menu"
          anchorEl={anchorEl}
          open={openDownloadReportMenu}
          onClose={handleCloseDownloadReportButton}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem
            onClick={() =>
              handleMenuItemClick(DOWNLOAD_REPORT_SELECTION.CURRENT_SELECTION)
            }
            disabled={!isEnabledDownloadSectorOption}
          >
            {t("horizon.brand_equity.download.current_selection")}
          </MenuItem>
          <MenuItem
            disabled
            onClick={() => handleMenuItemClick(DOWNLOAD_REPORT_SELECTION.ALL)}
          >
            {t("horizon.brand_equity.download.all_sectors")}
          </MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
};

export default Selectors;
