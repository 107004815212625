import SnackBar from "@components/SnackBar";
import { AUTO_HIDE_ALERT_ERROR } from "@constants/time";
import { HorizonContext } from "@context";
import BarChartHorizontal from "@dive.tv/dss-ui-graphs/BarChart/BarChartHorizontal";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Paper,
  SnackbarCloseReason,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { useSectors, useYougovKPIs } from "@services/api/horizon";
import { useIndustryTrendMetrics } from "@services/api/horizon/IndustryTrendMetrics";
import { IndustryTrendMetric } from "@services/api/model/horizon";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../Table";
import {
  formatRankValue,
  getKpiChartTitle,
  getTooltipHelper,
  toCustomChartData,
  toCustomTableData
} from "./utils";
import { TableHeadCell } from "../Table/types";

const IndustryTrendCharts = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentCtx } = useContext(HorizonContext);
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [page, setPage] = useState(0);

  const handleCloseErrorAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
  };

  const { data: sectors } = useSectors({ countryId: currentCtx.countryId });

  const { data: yougovKPIs } = useYougovKPIs();

  const current_date: Date = useMemo(() => new Date(), []);

  const columns: TableHeadCell[] = useMemo(() => {
    return [
      { id: "id", label: t("horizon.industry_trend.rank"), numeric: true },
      { id: "label", label: t("horizon.industry_trend.brand"), numeric: false },
      {
        id: "value",
        label: `${current_date.getFullYear() + 1} vs ${current_date.getFullYear()}`,
        numeric: true
      }
    ];
  }, [current_date, t]);

  const { current: currentTooltip, predicted: predictedTooltip } =
    useMemo(() => {
      return getTooltipHelper(t, currentCtx.industryTrendCtx.timePeriod);
    }, [t, currentCtx.industryTrendCtx.timePeriod]);

  const brandsById: Record<string, string> = useMemo(() => {
    const brandsByIdTmp: Record<string, string> = {};
    (sectors ?? [])
      .filter(({ is_visible }) => is_visible)
      .forEach((sector) => {
        sector.brands.forEach((brand) => {
          brandsByIdTmp[brand.id.toString()] = brand.label;
        });
      });
    return brandsByIdTmp;
  }, [sectors]);

  const sectorBrandIds: string[] = useMemo(() => {
    const brandIds: string[] = [];
    (sectors ?? [])
      .filter(({ id }) => id.toString() === currentCtx.sectorId)
      .forEach((sector) => {
        sector.brands.forEach((brand) => {
          brandIds.push(brand.id.toString());
        });
      });
    return brandIds;
  }, [sectors, currentCtx.sectorId]);

  const { data, isLoading } = useIndustryTrendMetrics({
    sectorId: currentCtx.sectorId,
    kpiId: currentCtx.kpiId,
    brandIds:
      currentCtx.industryTrendCtx.brandIds.length > 0
        ? currentCtx.industryTrendCtx.brandIds
        : sectorBrandIds,
    timePeriod: currentCtx.industryTrendCtx.timePeriod
  });

  const kpiById: Record<string, string> = useMemo(() => {
    const kpiByIdTmp: Record<string, string> = {};
    (yougovKPIs ?? []).forEach((kpi) => {
      kpiByIdTmp[kpi.id] = kpi.label;
    });
    return kpiByIdTmp;
  }, [yougovKPIs]);

  const { dataCurrent, dataPredicted, dataRanking } = useMemo(() => {
    const dataCurrent = toCustomChartData(
      toCustomTableData(
        data ?? [],
        brandsById,
        (data: IndustryTrendMetric) => data.current.value,
        "asc"
      )
    );

    const dataPredicted = toCustomChartData(
      toCustomTableData(
        data ?? [],
        brandsById,
        (data: IndustryTrendMetric) => data.predicted.value,
        "asc"
      )
    );
    const dataRanking = toCustomTableData(
      data ?? [],
      brandsById,
      (data: IndustryTrendMetric) => data.variation
    );
    return { dataCurrent, dataPredicted, dataRanking };
  }, [data, brandsById]);

  const brandsWithNoDataMessage: string = useMemo(() => {
    const brandNoData = (data ?? [])
      .filter(
        (brandData) =>
          brandData.variation === null ||
          brandData.current.value === null ||
          brandData.predicted.value === null
      )
      .map(
        (brandData) =>
          `${brandsById[brandData.brand_id] ?? brandData.brand_id.toString()}`
      );
    if (brandNoData.length > 0) {
      setOpenErrorAlert(true);
    }
    return brandNoData.length > 0
      ? `${t("horizon.industry_trend.no_data_for_brands")}: ${brandNoData.join(" , ")}`
      : "";
  }, [data, brandsById, t]);

  const currentKpiChartTitle: string = useMemo(() => {
    return currentCtx.kpiId.length > 0
      ? getKpiChartTitle(
          kpiById[currentCtx.kpiId] ?? kpiById,
          current_date.getFullYear()
        )
      : "";
  }, [current_date, kpiById, currentCtx.kpiId]);

  const futureKpiChartTitle: string = useMemo(() => {
    return currentCtx.kpiId.length > 0
      ? getKpiChartTitle(
          kpiById[currentCtx.kpiId] ?? kpiById,
          current_date.getFullYear() + 1
        )
      : "";
  }, [current_date, kpiById, currentCtx.kpiId]);

  useEffect(() => {
    setPage(0);
  }, [currentCtx.sectorId]);

  const areSelectorsClicked: boolean = useMemo(() => {
    return (
      currentCtx.sectorId.length > 0 &&
      currentCtx.kpiId.length > 0 &&
      currentCtx.industryTrendCtx.timePeriod.length > 0 &&
      (currentCtx.industryTrendCtx.brandIds.length > 0 ||
        sectorBrandIds.length > 0)
    );
  }, [currentCtx, sectorBrandIds]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: "#F2F4F7",
        height: "100%",
        padding: 1
      }}
    >
      <SnackBar
        id="industry_trend"
        message={brandsWithNoDataMessage}
        color="error"
        open={openErrorAlert}
        autoHideDuration={AUTO_HIDE_ALERT_ERROR}
        handleClose={handleCloseErrorAlert}
      />
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            paddingLeft: "1rem",
            paddingRight: "1rem"
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            {t("horizon.industry_trend.marketing_evolution")}
          </Typography>
          <Grid
            container
            columnSpacing={isTablet ? 0 : 2}
            rowSpacing={isTablet ? 2 : 0}
            sx={{
              height: "100%",
              paddingBottom: isTablet ? 4 : 1
            }}
          >
            {areSelectorsClicked && dataCurrent.length === 0 && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    padding: 1,
                    height: "100%",
                    minHeight: isTablet ? "620px" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center"
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: isTablet ? "column" : "row",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <SearchOffIcon
                      fontSize="large"
                      sx={{ color: theme.palette.primary.main }}
                    />
                    <Typography variant="h4">
                      {t("common.no_data_available")}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {dataRanking.length > 0 ? (
              <Grid item xs={isTablet ? 12 : 4}>
                <Card
                  sx={{
                    padding: 1,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "stretch"
                  }}
                >
                  <CardHeader
                    sx={{ padding: 0 }}
                    title={
                      <Typography variant="h6">
                        {t("horizon.industry_trend.rank_by_variation")}
                      </Typography>
                    }
                  />
                  <CardContent
                    sx={{
                      height: "100%",
                      padding: "0 !important",
                      margin: 0,
                      overflow: "auto",
                      flexGrow: 1
                    }}
                  >
                    <CustomTable
                      columns={columns}
                      rows={dataRanking}
                      formatFn={formatRankValue}
                      defaultRowsPerPage={10}
                      page={page}
                      setPage={setPage}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {dataCurrent.length > 0 ? (
              <Grid item xs={isTablet ? 12 : 4}>
                <Card
                  sx={{
                    padding: 1,
                    height: "100%",
                    minHeight: isTablet ? "720px" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "stretch"
                  }}
                >
                  <CardHeader
                    sx={{ padding: 0 }}
                    title={
                      <Typography variant="h6">
                        {currentKpiChartTitle}
                      </Typography>
                    }
                  />
                  <CardContent
                    sx={{
                      height: "100%",
                      padding: "0 !important",
                      margin: 0,
                      flexGrow: 1,
                      position: "relative"
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <BarChartHorizontal
                        data={dataCurrent}
                        color="#2645F5"
                        labelTextColor="white"
                        valueFormatter={formatRankValue}
                        responsiveBarProps={{
                          padding: 0.5,
                          enableGridX: true,
                          axisBottom: {
                            tickRotation: 45
                          },
                          axisLeft: {
                            tickRotation: -45
                          }
                        }}
                      ></BarChartHorizontal>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ fontSize: 14 }}>
                    <span>* {currentTooltip}</span>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}
            {dataPredicted.length > 0 ? (
              <Grid item xs={isTablet ? 12 : 4}>
                <Card
                  sx={{
                    padding: 1,
                    height: "100%",
                    minHeight: isTablet ? "720px" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "stretch"
                  }}
                >
                  <CardHeader
                    sx={{ padding: 0 }}
                    title={
                      <Typography variant="h6">
                        {futureKpiChartTitle}
                      </Typography>
                    }
                  />
                  <CardContent
                    sx={{
                      height: "100%",
                      padding: "0 !important",
                      margin: 0,
                      flexGrow: 1,
                      position: "relative"
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <BarChartHorizontal
                        data={dataPredicted}
                        color="#2645F5"
                        labelTextColor="white"
                        valueFormatter={formatRankValue}
                        responsiveBarProps={{
                          padding: 0.5,
                          enableGridX: true,
                          axisBottom: {
                            tickRotation: 45
                          },
                          axisLeft: {
                            tickRotation: -45
                          }
                        }}
                      ></BarChartHorizontal>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ fontSize: 14 }}>
                    <span>* {predictedTooltip}</span>
                  </CardActions>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

export default IndustryTrendCharts;
