export enum IndustryPageTime {
  QTD = "QTD",
  YTD = "YTD"
}

export const AUTO_HIDE_ALERT_ERROR = 6000;

export const QUARTER_MONTHS: { [key: number]: number[] } = {
  1: [0, 1, 2],
  2: [3, 4, 5],
  3: [6, 7, 8],
  4: [9, 10, 11]
};

export const getQuarterFromMonth = (month: number): number => {
  return month <= 3 ? 1 : month <= 6 ? 2 : month <= 9 ? 3 : 4;
};

export const getQuarterMonths = (quarter: number): string => {
  if (!(quarter in QUARTER_MONTHS)) {
    return "";
  }
  const current_date = new Date();
  const months = QUARTER_MONTHS[quarter];
  const monthNames = months.map((month) => {
    const monthDate = new Date(`${current_date.getFullYear()}-${month + 1}-01`);
    return monthDate.toLocaleString("en-US", { month: "long" });
  });

  return monthNames.join(" , ");
};
